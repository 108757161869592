import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(p => p.HomePage),
  },
  {
    path: 'contacto',
    loadComponent: () => import('./pages/contact/contact.page').then(p => p.ContactPage),
  },
  {
    path: 'funcionamiento',
    loadComponent: () => import('./pages/how-does-it-work/how-does-it-work.page').then(p => p.HowDoesItWorkPage),
  },
  {
    path: 'iniciar-sesion',
    loadComponent: () => import('./pages/login/login.page').then(p => p.LoginPage),
  },
  {
    path: 'politicas-de-privacidad',
    loadComponent: () => import('./pages/privacy-policies/privacy-policies.page').then(p => p.PrivacyPoliciesPage),
  },
  {
    path: 'pymes/:id',
    loadComponent: () => import('./pages/pyme-profile/pyme-profile.page').then(p => p.PymeProfilePage),
  },
  {
    path: 'registro',
    loadComponent: () => import('./pages/register/register.page').then(p => p.RegisterPage), 
  },
  {
    path: 'terminos-y-condiciones',
    loadComponent: () => import('./pages/terms-and-conditions/terms-and-conditions.page').then(p => p.TermsAndConditionsPage),
  },
];
